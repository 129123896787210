import React from "react"
import styled from "@emotion/styled"

export const HeroTitle = () => <Title>evoli hemförsäkring</Title>

const Title = styled.h1`
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #292944;
  text-align: left;
  max-width: 558px;
  margin-bottom: 21px;
  line-height: 1.22;

  @media (max-width: 896px) and (max-height: 600px) {
    font-size: 34px;
    font-weight: 600;
  }

  @media (max-width: 736px) {
    font-size: 38px;
    font-weight: 600;
    /* line-height: 20px; */
  }

  /* @media (max-width: 690px) {
    font-size: 54px;
    font-weight: 500;
    line-height: 60px;
  } */

  @media (max-width: 610px) {
    font-size: 45px;
    font-weight: 500;
    max-width: 300px;
  }

  @media (max-width: 575px) {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
    /* line-height: 40px; */
  }

  @media (max-width: 375px) {
    padding: 0 7px;
  }

  @media (max-width: 320px) {
    font-size: 26px;
    font-weight: 600;
    /* line-height: 32px; */
  }
`
