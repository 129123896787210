import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo"
import HomePageHero from "../../../components/PageComponents/HomePage/Hero/Hero"
import { CardSection } from "../../../components/PageComponents/HomePage/CardSection/CardSection"
import { useTheme } from "emotion-theming"
import styled from "@emotion/styled"
import { Section } from "../../../components/PageComponents/Section"
import { ContentContainer } from "../../../components/PageComponents/ContentContainer"
import {
  NoCuringPeriodSection,
  RecoSection,
  SafeWithUsSection,
} from "../../../components/PageComponents/IndexPage/DefaultSections/DefaultSections"

const ProduktInsuranceStartPage = ({ location }) => {
  const theme = useTheme()

  return (
    <Layout>
      <SEO title="evoli - Hemförsäkring" />

      <HomePageHero />
      <CardSection />

      <SubSections>
        <NoCuringPeriodSection />
        <SafeWithUsSection />
        <RecoSection />
      </SubSections>

      {/* <Section bgColor={theme.colors.primaryBackground}>
        <ContentContainer>
          <p>
            Försäkringsgivare är Eir Försäkring, som står under svenska
            Finansinspektionens tillsyn. evoli AB är registrerad som
            försäkringsförmedlare och står under svenska Finansinspektionens
            tillsyn. Mer information om förmedling hittar du här.{" "}
          </p>{" "}
        </ContentContainer>
      </Section> */}
      {/* <HomePageHero /> */}
      {/* <CardSection /> */}
    </Layout>
  )
}

export default ProduktInsuranceStartPage

const SubSections = styled.div`
  margin-top: 160px;
  margin-bottom: 160px;
  margin: 160px 0;

  @media (max-width: 768px) {
    margin: 60px 0;
  }

  div {
    margin-bottom: 60px;
  }
`
