import React from "react"
import styled from "@emotion/styled"
import Icon from "../../../Icon/Icon"

const CardHead = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #5558ea;
  margin-bottom: 7px;

  @media (min-width: 700px) {
    margin-bottom: 0px;
  }

  div {
    margin-right: 6px;

    @media (min-width: 700px) {
      margin-right: 16px;
    }
  }

  @media (min-width: 700px) {
    font-size: 20px;
  }

  @media (max-width: 1024px) {
    svg {
      width: 20px;
      height: 20px;
    }
    div {
      width: 35px;
      height: 35px;
    }
  }

  @media (max-width: 414px) {
    svg {
      width: 12px;
      height: 12px;
    }
    div {
      width: 20px;
      height: 20px;
    }
  }
`
const CardBody = styled.p`
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: #7a7a94;
  margin: 0;
  /* margin-left: 66px; */
  padding-right: 25px;

  @media (max-width: 1024px) {
    margin-left: 0;
    margin-top: 7px;
    padding-right: 0;
  }

  @media (min-width: 700px) {
    font-size: 14px;
  }

  @media (max-width: 700px) {
    display: none;
  }
`

const CardBodyMobile = styled.p`
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: #7a7a94;
  margin: 0;

  @media (min-width: 700px) {
    display: none;
  }
`

const Cards = styled.section`
  width: 100%;
  max-width: 1120px;
  margin-top: 10px;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  @media (max-width: 1024px) {
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  @media (max-width: 900px) {
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(205px, 1fr));
  }

  @media (max-width: 742px) {
    grid-gap: 24px;
    grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
    margin-top: 20px;
  }

  @media (max-width: 414px) {
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  }

  @media (max-width: 616px) {
    #Djurkollision {
      display: none;
    }
  }
`

const Card = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.05);
  padding: 20px;

  @media (max-width: 900px) {
    padding: 12px;
  }
`

export const CardGroup = () => (
  <Cards>
    {CardContent.map(item => (
      <Card key={item.header} id={item.header}>
        <CardHead>
          {/* <Icon
            name={item.icon}
            bgColor="#c4ffe1"
            iconColor="#5558ea"
            size="50"
            iconSize="28px"
          /> */}
          {item.header}
        </CardHead>
        <CardBody>{item.longText}</CardBody>
        <CardBodyMobile>{item.text}</CardBodyMobile>
      </Card>
    ))}
  </Cards>
)

const CardContent = [
  {
    icon: "Egendomsskydd",
    header: "Egendomsskydd lösöre",
    text:
      "Ersätter dig för skador på dina saker, exempelvis vid brand och stöld.",
    longText:
      "Ersätter dig för skador på dina saker, exempelvis vid brand och stöld.",
  },
  {
    icon: "Reseskydd",
    header: "Reseskydd",
    text:
      "Ersätter bland annat kostnad för sjukvård om behovet skulle uppstå på resa.",
    longText:
      " Ersätter bland annat kostnad för sjukvård om behovet skulle uppstå på resa.",
  },
  {
    icon: "Rättsskydd",
    header: "Rättsskydd",
    text: "Kan ersätta dig för kostnad som uppkommer vid en tvist",
    longText: "Kan ersätta dig för kostnad som uppkommer vid en tvist.",
  },
  {
    icon: "Överfallsskydd",
    header: "Överfallsskydd",
    text: "Kan ersätta dig om du utsätts för ett överfall.",
    longText: "Kan ersätta dig om du utsätts för ett överfall.",
  },
  {
    icon: "Ansvarsskydd",
    header: "Ansvarsskydd",
    text: "Kan ersätta dig om du skulle bli krävd på skadestånd",
    longText: "Kan ersätta dig om du skulle bli krävd på skadestånd",
  },
]

const AddOns = [
  {
    icon: "Drulle",
    header: "Drulle",
    text: "En allriskförsäkring för dina saker.",
    longText: "Ersättning för längre bärgningar och assistans vid vägen.",
  },
  {
    icon: "Resextra",
    header: "Resextra",
    text: "Ett utökat skydd för resan, bland annat avbeställningsskydd.",
    longText: "Ett utökat skydd för resan, bland annat avbeställningsskydd.",
  },
  {
    icon: "Bostadsrätt",
    header: "Bostadsrätt",
    text: "Ett skydd för den fasta inredningen för dig som bor i bostadsrätt.",
    longText:
      "Ett skydd för den fasta inredningen för dig som bor i bostadsrätt.",
  },
]
