import React from "react"
import styled from "@emotion/styled"

export const HeroParagraph = () => (
  <Text>
    En hemförsäkring som ger ett bra skydd för både dig och dina saker, till ett
    lågt pris.
    <br />
    <br />
    <b>
      Ring oss på <a href="tel:0660-66 06 66">0660-66 06 66</a> för ett
      prisförslag.
    </b>
    <br />
    <br />
    För fullständig information, se villkor: <br />
    <a
      href="/forsakringsvillkor/Forsakringsvillkor_hem_2021-12-01.pdf"
      target="_blank"
    >
      Försäkringsvillkor
    </a>
    ,{" "}
    <a
      href="/produktfaktablad/Produktfaktablad-Hemforsakring.pdf"
      target="_blank"
    >
      Produktfaktablad (IPID)
    </a>
    ,{" "}
    <a href="/forkopsinformation/Forkopsinformation-Hem.pdf" target="_blank">
      Förköpsinformation
    </a>
    <br />
    <br />
    Behöver du anmäla en skada? Det gör du enkelt{" "}
    <a
      href="https://support.evoli.se/sv/articles/3778940-anmal-skada"
      target="_blank"
    >
      här
    </a>
    .
  </Text>
)

const Text = styled.p`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  color: ${props => props.theme.colors.HeroTextBox.color};
  max-width: 558px;
  /* margin-bottom: 36px; */

  a {
    color: #5558ea;
    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 896px) and (max-height: 600px) {
    font-size: 16px;
  }

  @media (max-width: 414px) {
    font-size: 14px;
    margin-bottom: 14px;
    line-height: 1.57;
    max-width: 333px;
  }

  @media (max-width: 375px) {
    padding: 0 7px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
    margin-bottom: 15px;
  }
`
